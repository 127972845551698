import PropTypes from 'prop-types';
import { iconList } from '../utils/iconSrc';

export const RichTextPropTypes = PropTypes.shape({
  raw: PropTypes.string,
  references: PropTypes.arrayOf(PropTypes.object),
});

export const LongTextRequired = PropTypes.shape({
  internal: PropTypes.shape({
    content: PropTypes.string.isRequired,
  }),
}).isRequired;

export const ImageProps = PropTypes.shape({
  description: PropTypes.string.isRequired,
  file: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }),
});

export const FileDownload = PropTypes.shape({
  fileName: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  contentType: PropTypes.string,
  details: PropTypes.shape({
    size: PropTypes.number.isRequired,
  }),
});

export const PageContextProps = PropTypes.shape({
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  internal: PropTypes.shape({
    type: PropTypes.string.isRequired,
  }),
  title: PropTypes.string.isRequired,
  pageInformation: PropTypes.shape({
    seoTitle: PropTypes.string,
    seoDescription: PropTypes.object.isRequired,
    deindexPage: PropTypes.bool,
    allowTracking: PropTypes.bool,
    pageThumbnail: PropTypes.object,
  }),
});

export const NamedIconProps = PropTypes.oneOf(Object.keys(iconList));

export const ButtonColorProps = PropTypes.oneOf([
  'primary',
  'secondary-standard',
  'secondary-outline',
  'secondary-on-dark',
]);
