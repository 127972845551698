export const WEBCHAT_ROOT_ID = 'webchat-root';

// UAT
// export const WEBCHAT_STATUS_ENDPOINT =
//   'https://webchat-serverless-8864.twil.io/get-chat-state';
// export const SCRIPT_PATH =
//   'https://cdn.ciptex.com/shelter/chat/2.0.0/webchat-uat.js';
// export const STYLES_PATH =
//   'https://cdn.ciptex.com/shelter/chat/2.0.0/webchat-uat.css';

// PRODUCTION
export const WEBCHAT_STATUS_ENDPOINT = `https://webchat-serverless-4783.twil.io/get-chat-state`;
export const SCRIPT_PATH =
  'https://cdn.ciptex.com/shelter/chat/2.0.0/webchat-prod.js';
export const STYLES_PATH =
  'https://cdn.ciptex.com/shelter/chat/2.0.0/webchat-prod.css';

export const PATHS_TO_LOAD_ON = [
  '/professional_resources/advice/housing/webchat',
  '/professional_resources/debt_advice',
  '/get_help/webchat',
];
