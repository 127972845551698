import { dateAsString } from './dates';

export const sendEvent = (data) => {
  if (!window.dataLayer) window.dataLayer = [];
  window.dataLayer.push(data);
};

export const sendPageView = (contentType, pageTitle, createdAt, updatedAt) =>
  sendEvent({
    contentType,
    pageTitle,
    contentPublishedDate: !createdAt
      ? 'unavailable'
      : dateAsString(createdAt, 'DD/MM/YYYY'),
    contentModifiedDate: !updatedAt
      ? 'unavailable'
      : dateAsString(updatedAt, 'DD/MM/YYYY'),
  });
