import styled, { css } from 'styled-components';
import { breakpoint } from '../theme/breakpoint';

const headerMargins = css`
  margin-top: ${({ theme }) => theme.spacing.medium};
  margin-bottom: ${({ theme }) => theme.spacing.standard};
`;

/**
 * These are legacy CSS injections. Hopefully we can replace them with
 * values sourced directly from the theme.
 *
 * Prefer component.as('element') for styling where semantics could be different
 */
export const h1 = css`
  font-size: ${({ theme }) => theme.fontsize.mobile.h1};
  line-height: ${({ theme }) => theme.lineHeights.mobile.h1};
  ${headerMargins};
  ${breakpoint.tablet`
    font-size: ${({ theme }) => theme.fontsize.h1};
    line-height: ${({ theme }) => theme.lineHeights.h1};
  `};
`;

export const h2 = css`
  font-size: ${({ theme }) => theme.fontsize.mobile.h2};
  line-height: ${({ theme }) => theme.lineHeights.mobile.h2};
  ${headerMargins};
  ${breakpoint.tablet`
    font-size: ${({ theme }) => theme.fontsize.h2};
    line-height: ${({ theme }) => theme.lineHeights.h2};
  `};
`;

export const h3 = css`
  font-size: ${({ theme }) => theme.fontsize.mobile.h3};
  line-height: ${({ theme }) => theme.lineHeights.mobile.h3};
  ${headerMargins};
  ${breakpoint.tablet`
    font-size: ${({ theme }) => theme.fontsize.h3};
    line-height: ${({ theme }) => theme.lineHeights.h3};
  `};
`;

export const h4 = css`
  font-size: ${({ theme }) => theme.fontsize.mobile.h4};
  line-height: ${({ theme }) => theme.lineHeights.mobile.h4};
  ${headerMargins};
  ${breakpoint.tablet`
    font-size: ${({ theme }) => theme.fontsize.h4};
    line-height: ${({ theme }) => theme.lineHeights.h4};
  `};
`;

/**
 * These headings are for use in Rich Text fields, and anywhere else that the standard spacing
 * is needed.
 *
 * The above CSS snippets are for where it's just the font-size and line height that are needed
 * e.g. in custom elements.
 *
 * Note: These styles will also inherit from the global typography sheet, which also sources values
 * from the theme.
 */
export const Heading1 = styled.h1`
  ${h1};
`;

export const Heading2 = styled.h2`
  ${h2};
`;

export const Heading3 = styled.h3`
  ${h3};
`;

export const Heading4 = styled.h4`
  ${h4};
`;

/**
 * Note: The design style guide specifies the space between
 * H2 and Lists. Since the Heading bottom margins are smaller
 * they will collapse, so we just apply the full margin here.
 *
 * https://css-tricks.com/what-you-should-know-about-collapsing-margins/
 * https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Box_Model/Mastering_margin_collapsing
 */
export const OrderedList = styled.ol`
  > li {
    margin-bottom: ${({ theme }) => theme.spacing.standard};
  }
  ${Heading2} + &, ${Heading3} + & , ${Heading4} + & {
    margin-top: ${({ theme }) => theme.spacing.standard};
  }
`;

export const UnorderedList = styled.ul`
  > li {
    margin-bottom: ${({ theme }) => theme.spacing.standard};
  }
  ${Heading2} + &, ${Heading3} + & , ${Heading4} + & {
    margin-top: ${({ theme }) => theme.spacing.standard};
  }
`;
