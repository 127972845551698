import React from 'react';
import { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import { CookiesProvider } from 'react-cookie';
import theme from '../theme/variables';
import SentryProvider from '../sentry';

/**
 * Wraps an element in this App's context providers
 */
const Providers = ({ children, sentry }) => (
  <SentryProvider sentry={sentry}>
    <ThemeProvider theme={theme}>
      <CookiesProvider>{children}</CookiesProvider>
    </ThemeProvider>
  </SentryProvider>
);

Providers.propTypes = {
  children: PropTypes.node,
  sentry: PropTypes.object,
};

export default Providers;
