/* global */
/* eslint-disable react/prop-types */
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import ReactModal from 'react-modal';
import React from 'react';
import * as Sentry from '@sentry/browser';
import Providers from './src/components/providers';
import config from './sentry-config';
import { getQueryParams } from './src/utils/query-params';
import { setStorageValue } from './src/utils/session-storage';

export function onClientEntry() {
  ReactModal.setAppElement('#___gatsby');

  const queryParam = getQueryParams(window.location.search);
  if (Object.keys(queryParam).length) {
    setStorageValue('query', queryParam, 'storedEntry');
  }

  if (process.env.SENTRY_DSN) {
    Sentry.init({
      ...config,
    });

    Sentry.configureScope((scope) => {
      scope.setTag('origin', 'browser');
      scope.setTag('site', process.env.SHELTER_SITE);
    });
  }
}

// eslint-disable-next-line react/prop-types
export const wrapRootElement = ({ element }) => (
  <Providers sentry={process.env.SENTRY_DSN && Sentry}>{element}</Providers>
);

export { wrapPageElement } from './gatsby-shared';

export const shouldUpdateScroll = () => true;
