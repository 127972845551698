import { h1, h2, h3, h4 } from '../styled/typography';
import colorsMap from './colors';

const px = (val) => `${val}px`;

const theme = {};

const scales = {
  space: {
    tiny: 8,
    small: 12,
    standard: 16,
    medium: 24,
    large: 32,
    xlarge: 40,
    xxlarge: 64,
  },
  fontSizes: {
    system: 12,
    small: 14,
    base: 18,
    h4: 21,
    h3: 25,
    h2: 36,
    h1: 50,
  },
  lineHeights: {
    system: 1.4,
    small: 1.4,
    base: 1.4,
    h4: 1.4,
    h3: 1.4,
    h2: 1.3,
    h1: 1.3,
  },
  mobile: {
    fontSizes: {
      system: 12,
      small: 14,
      base: 18,
      h4: 21,
      h3: 25,
      h2: 28,
      h1: 36,
    },
    lineHeights: {
      system: 1.4,
      small: 1.4,
      base: 1.4,
      h4: 1.4,
      h3: 1.4,
      h2: 1.3,
      h1: 1.3,
    },
  },
};

theme.name = 'Brand theme';
theme.palette = {
  ...colorsMap,
};

theme.fonts = {
  primary: 'Barlow, -apple-system, BlinkMacSystemFont, Roboto, sans-serif',
  header: 'Barlow, -apple-system, BlinkMacSystemFont, Roboto, sans-serif',
  condensed:
    '"Barlow Condensed", -apple-system, BlinkMacSystemFont, Roboto, sans-serif',
  quote: 'Georgia, serif',
};

theme.sizes = {
  maxWidth: '1140px',
  container: '960px',
};

theme.borderradius = {
  small: '3px',
};

theme.headers = {
  h1,
  h2,
  h3,
  h4,
};

theme.fontsize = {
  // From the design system
  system: px(scales.fontSizes.system),
  small: px(scales.fontSizes.small),
  base: px(scales.fontSizes.base),
  h4: px(scales.fontSizes.h4),
  h3: px(scales.fontSizes.h3),
  h2: px(scales.fontSizes.h2),
  h1: px(scales.fontSizes.h1),

  // Legacy (-small)
  tiny: px(scales.fontSizes.system),
  standard: px(scales.fontSizes.base),
  larger: px(scales.fontSizes.h3),
  hero: px(scales.fontSizes.h1),

  mobile: {
    system: px(scales.mobile.fontSizes.system),
    small: px(scales.mobile.fontSizes.small),
    base: px(scales.mobile.fontSizes.base),
    h4: px(scales.mobile.fontSizes.h4),
    h3: px(scales.mobile.fontSizes.h3),
    h2: px(scales.mobile.fontSizes.h2),
    h1: px(scales.mobile.fontSizes.h1),
  },
};

theme.lineHeights = {
  // From the design system
  system: scales.lineHeights.system,
  small: scales.lineHeights.small,
  base: scales.lineHeights.base,
  h4: scales.lineHeights.h4,
  h3: scales.lineHeights.h3,
  h2: scales.lineHeights.h2,
  h1: scales.lineHeights.h1,

  mobile: {
    system: scales.mobile.lineHeights.system,
    small: scales.mobile.lineHeights.small,
    base: scales.mobile.lineHeights.base,
    h4: scales.mobile.lineHeights.h4,
    h3: scales.mobile.lineHeights.h3,
    h2: scales.mobile.lineHeights.h2,
    h1: scales.mobile.lineHeights.h1,
  },
};

theme.fontWeight = {
  bold: '700',
  normal: '400',
};

theme.divider = {
  small: `1px solid ${theme.palette.border}`,
  standard: `3px solid ${theme.palette.border}`,
};

theme.boxshadow = {
  standard: '0 2.5px 6px rgba(0,0,0,0.24),0 2.5px 6px rgba(0,0,0,0.19)',
  small: '0 1.5px 4px rgba(0,0,0,0.24),0 1.5px 6px rgba(0,0,0,0.12)',
  menu: '4px 4px 8px 0px rgba(0,0,0,0.25)',
};

theme.spacing = {
  tiny: px(scales.space.tiny),
  small: px(scales.space.small),
  standard: px(scales.space.standard),
  medium: px(scales.space.medium),
  large: px(scales.space.large),
  xlarge: px(scales.space.xlarge),
  xxlarge: px(scales.space.xxlarge),
};

export default theme;
