export function onRouteUpdate({ prevLocation }) {
  // GTM uses this session storage entry to prevent double counting of the first page that the user visits
  sessionStorage.setItem('firstPage', !prevLocation);

  if (
    window.CookieControl !== undefined &&
    window.CookieControl.cookieCategoryRevokedNeedRefresh !== undefined
  ) {
    window.location.reload();
  }
}
