import React, { useEffect, createContext, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

import { sendPageView } from '../../utils/analytics';
import { PageContextProps } from '../../prop-types';

export const defaultPageMeta = {
  breadcrumbs: [],
  createdAt: 0,
  updatedAt: 0,
  type: 'default',
  title: '',
  description: '',
  thumbnail: {},
  deindexPage: false,
  path: '',
  url: '',
  allowTracking: null,
};

export const PageMeta = createContext(defaultPageMeta);

const Page = ({ children, pageContext, path }) => {
  const {
    createdAt,
    updatedAt,
    breadcrumbs,
    title,
    internal: { type },
    pageInformation,
    // eslint-disable-next-line camelcase
    contentful_id: contentfulId,
  } = pageContext;

  const {
    seoTitle,
    seoDescription,
    deindexPage,
    pageThumbnail: thumbnail,
    allowTracking,
  } = pageInformation || {};

  const url = `${process.env.URL}${path}`;

  // include default head if the page doesn't provide a custom head
  // const includeDefaultHead = !children.find(child => child.type.displayName === 'HtmlHead');

  useEffect(() => {
    const shouldRedactPixels = () => {
      const isSensitivePage =
        pageContext.internal.type === 'ContentfulPageAdvice' ||
        (process.env.SHELTER_SITE === 'england' &&
          !!path.match(/^\/get_help/)) ||
        (process.env.SHELTER_SITE === 'scotland' &&
          !!path.match(/^\/about_us\/contact_us/));

      // All non-advice pages should have pixels
      if (!isSensitivePage) {
        return false;
      }

      // If advice page but has opted in, don't redact pixels
      if (isSensitivePage && allowTracking) {
        return false;
      }

      // If advice page and hasn't opted in, redact pixels
      if (isSensitivePage) {
        return true;
      }
    };

    if (window?.dataLayer) {
      window.dataLayer.push({
        redactPixels: shouldRedactPixels(),
      });
    }

    // Analytics event, page view is fired on gatsby-route-change which occurs after
    sendPageView(type, seoTitle || title, createdAt, updatedAt);
  }, [
    type,
    createdAt,
    title,
    seoTitle,
    updatedAt,
    pageContext.internal.type,
    path,
    allowTracking,
    pageInformation,
  ]);

  const memoisedValues = useMemo(
    () => ({
      updatedAt,
      createdAt,
      breadcrumbs,
      type,
      title: seoTitle || title,
      description: seoDescription && seoDescription.internal.content,
      thumbnail,
      deindexPage,
      contentfulId,
      path,
      url,
    }),
    [
      breadcrumbs,
      contentfulId,
      createdAt,
      deindexPage,
      path,
      seoDescription,
      seoTitle,
      thumbnail,
      title,
      type,
      updatedAt,
      url,
    ]
  );

  return (
    <PageMeta.Provider value={memoisedValues}>{children}</PageMeta.Provider>
  );
};

Page.propTypes = {
  children: PropTypes.element,
  pageContext: PageContextProps,
  path: PropTypes.string.isRequired,
};

export default Page;

export const usePageMeta = () => useContext(PageMeta);
