import dayjs from 'dayjs';

// Format date as string in required format
// Formatting options: https://bit.ly/2R9uVsh
export const dateAsString = (date, format) =>
  dayjs(date).format(format).toString();

export const getDayOfTheWeek = (date = new Date()) =>
  dateAsString(date, 'dddd');

export const dateAsIsoString = (date) => dayjs(date).toISOString();
