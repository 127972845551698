import { consistentString } from './content-formatting';

import AlertCross from '../assets/svg/iconLib/alert-cross.svg';
import AlertInfo from '../assets/svg/iconLib/alert-info.svg';
import AlertWarning from '../assets/svg/iconLib/alert-warning.svg';
import Calendar from '../assets/svg/iconLib/calendar.svg';
import Chat from '../assets/svg/iconLib/chat.svg';
import ChevronDown from '../assets/svg/iconLib/chevron-down.svg';
import ChevronLeft from '../assets/svg/iconLib/chevron-left.svg';
import ChevronRight from '../assets/svg/iconLib/chevron-right.svg';
import ChevronUp from '../assets/svg/iconLib/chevron-up.svg';
import Clock from '../assets/svg/iconLib/clock.svg';
import Cross from '../assets/svg/iconLib/cross.svg';
import Download from '../assets/svg/iconLib/download.svg';
import Envelope from '../assets/svg/iconLib/email.svg';
import Location from '../assets/svg/iconLib/location.svg';
import Menu from '../assets/svg/iconLib/hamburger.svg';
import Next from '../assets/svg/iconLib/next.svg';
import Pause from '../assets/svg/iconLib/pause.svg';
import PauseInCircle from '../assets/svg/iconLib/pause-in-circle.svg';
import Play from '../assets/svg/iconLib/play.svg';
import PlayInCircle from '../assets/svg/iconLib/play-in-circle.svg';
import Phone from '../assets/svg/iconLib/phone.svg';
import Previous from '../assets/svg/iconLib/previous.svg';
import Printer from '../assets/svg/iconLib/printer.svg';
import Quotes from '../assets/svg/iconLib/quotes.svg';
import Refresh from '../assets/svg/iconLib/refresh.svg';
import Search from '../assets/svg/iconLib/search.svg';
import Share from '../assets/svg/iconLib/share.svg';
import Soundwave from '../assets/svg/iconLib/soundwave.svg';
import Speedometer from '../assets/svg/iconLib/speedometer.svg';
import Stop from '../assets/svg/iconLib/stop.svg';
import Tick from '../assets/svg/iconLib/tick.svg';
import Trophy from '../assets/svg/iconLib/trophy.svg';
import Document from '../assets/svg/iconLib/document.svg';
import Link from '../assets/svg/iconLib/link.svg';
import Minus from '../assets/svg/iconLib/minus.svg';
import Photo from '../assets/svg/iconLib/photo.svg';
import Plus from '../assets/svg/iconLib/plus.svg';
import Pound from '../assets/svg/iconLib/pound.svg';
import QuotesLrg from '../assets/svg/iconLib/quotes-lrg.svg';

// social
import SocialFacebook from '../assets/svg/iconLib/social-facebook.svg';
import SocialLinkedIn from '../assets/svg/iconLib/social-linkedin.svg';
import SocialTwitter from '../assets/svg/iconLib/social-twitter.svg';
import SocialWhatsapp from '../assets/svg/iconLib/social-whatsapp.svg';
import SocialInstagram from '../assets/svg/iconLib/social-instagram.svg';
import SocialTikTok from '../assets/svg/iconLib/social-tiktok.svg';

// old
import Car from '../assets/svg/iconLib/car-regular.svg';
import ClipboardChecklist from '../assets/svg/iconLib/clipboard-checklist-light.svg';
import FundingGoalMeter from '../assets/svg/iconLib/funding-goal-meter-light.svg';
import Lightbulb from '../assets/svg/iconLib/lightbulb-light.svg';
import MapUnfolded from '../assets/svg/iconLib/map-unfolded-light.svg';
import Wheelchair from '../assets/svg/iconLib/wheelchair-regular.svg';

// challenge icons
import ChippedTimeResults from '../assets/svg/iconLib/challenge-event/chipped-time-results.svg';
import FinishersMedal from '../assets/svg/iconLib/challenge-event/finishers-medal.svg';
import FreeBagTransfer from '../assets/svg/iconLib/challenge-event/free-bag-transfer.svg';
import FreeRacePhotos from '../assets/svg/iconLib/challenge-event/free-race-photos.svg';
import GoodieBags from '../assets/svg/iconLib/challenge-event/goodie-bags.svg';
import PostRaceCelebration from '../assets/svg/iconLib/challenge-event/post-race-celebration.svg';
import SportsMassage from '../assets/svg/iconLib/challenge-event/sports-massage.svg';
import TechnicalRunningTop from '../assets/svg/iconLib/challenge-event/technical-running-top.svg';

export const iconsStandard = {
  'alert-cross': AlertCross,
  'alert-info': AlertInfo,
  'alert-warning': AlertWarning,
  calendar: Calendar,
  'speech-bubble': Chat,
  'chevron-down': ChevronDown,
  'chevron-left': ChevronLeft,
  'chevron-right': ChevronRight,
  'chevron-up': ChevronUp,
  clock: Clock,
  cross: Cross,
  'open-book': Document,
  download: Download,
  envelope: Envelope,
  menu: Menu,
  link: Link,
  'map-marker': Location,
  minus: Minus,
  next: Next,
  telephone: Phone,
  pause: Pause,
  'pause-in-circle': PauseInCircle,
  photo: Photo,
  play: Play,
  'play-in-circle': PlayInCircle,
  plus: Plus,
  pound: Pound,
  previous: Previous,
  printer: Printer,
  quotes: Quotes,
  'quotes-lrg': QuotesLrg,
  refresh: Refresh,
  search: Search,
  share: Share,
  soundwave: Soundwave,
  speedometer: Speedometer,
  stop: Stop,
  tick: Tick,
  trophy: Trophy,
};

export const iconsSocial = {
  'social-facebook': SocialFacebook,
  'social-linkedin': SocialLinkedIn,
  'social-twitter': SocialTwitter,
  'social-whatsapp': SocialWhatsapp,
  'social-instagram': SocialInstagram,
  'social-tiktok': SocialTikTok,
};

export const iconsChallenge = {
  'chipped-time-results': ChippedTimeResults,
  'finishers-medal': FinishersMedal,
  'free-bag-transfer': FreeBagTransfer,
  'free-race-photos': FreeRacePhotos,
  'goodie-bags': GoodieBags,
  'post-race-celebration': PostRaceCelebration,
  'sports-massage': SportsMassage,
  'technical-running-top': TechnicalRunningTop,
};

export const iconsOld = {
  'exclamation-mark': AlertWarning,
  'clipboard-checklist': ClipboardChecklist,
  car: Car,
  'funding-goal-meter': FundingGoalMeter,
  lightbulb: Lightbulb,
  'map-unfolded': MapUnfolded,
  wheelchair: Wheelchair,
};

export const iconList = {
  ...iconsStandard,
  ...iconsSocial,
  ...iconsChallenge,
  ...iconsOld,
  'no-icon': null,
  default: null,
};

// returns the src of an svg icon from the above icon list
const iconSrc = (icon) => {
  const iconKey = consistentString(icon);

  if (iconList[iconKey] === undefined) {
    throw new Error(
      `No matching icon was found for ${icon}. Is it set as an option in the CMS? Has it been imported and included in the object above?`
    );
  }

  const chosenIcon = iconList[iconKey];

  if (chosenIcon) return chosenIcon;
};

export default iconSrc;
