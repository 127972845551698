import React from 'react';
// eslint-disable-next-line import/no-relative-packages
import { WEBCHAT_ROOT_ID } from './plugins/gatsby-plugin-webchat/constants';
import Page from './src/components/page';

// This is a Gatsby API that wraps every page element
// https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-script/#usage-in-gatsby-ssr-and-browser-apis
export const wrapPageElement = ({ element, props }) => {
  // gatsby will render some system pages (the default 404 index
  // page for example) which we can't wrap in a page component,
  // so we have to check here that this is a contentful page
  const { location } = props;
  if (props.pageContext && props.pageContext.internal) {
    return (
      <Page {...props} path={location.pathname}>
        {element}
        <div id={WEBCHAT_ROOT_ID} />
      </Page>
    );
  }
  return element;
};
