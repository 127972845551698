const SCOTLAND_SE_ID = '173bda1c-c7c3-427b-a383-1acd6b5599c3'; // Scotland housing advice

// Modified SnapEngage embed code, original can be found in the SnapEngage admin
// https://www.snapengage.com/app/settings
export function onRouteUpdate() {
  const isSELoaded = typeof window.SnapEngage !== 'undefined';
  if (!isSELoaded) {
    const seScript = document.createElement('script');
    seScript.async = true;
    seScript.src = `https://storage.googleapis.com/code.snapengage.com/js/${SCOTLAND_SE_ID}.js`;
    let done = false;

    /* eslint-disable no-multi-assign */
    seScript.onload = seScript.onreadystatechange = function () {
      if (
        !done &&
        (!this.readyState ||
          this.readyState === 'loaded' ||
          this.readyState === 'complete')
      ) {
        done = true;
        window.dispatchEvent(new Event('snapengage-ready')); // Webchat component listens for this event
      }
    };
    /* eslint-enable no-multi-assign */

    const getScript = document.getElementsByTagName('script')[0];
    getScript.parentNode.insertBefore(seScript, getScript);
  }
}
