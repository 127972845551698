export const getQueryParams = (query) =>
  query
    ? (/^[?#]/.test(query) ? query.slice(1) : query)
        .split('&')
        .reduce((params, param) => {
          const [key, value] = param.split('=');
          params[key] = value ? decodeURIComponent(value) : '';
          return params;
        }, {})
    : {};

export const buildQueryString = (params) =>
  `?${Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join('&')}`;
