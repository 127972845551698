import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

const SentryContext = createContext(null);

const SentryProvider = ({ children, sentry }) => (
  <SentryContext.Provider value={sentry}>{children}</SentryContext.Provider>
);

SentryProvider.propTypes = {
  children: PropTypes.node,
  sentry: PropTypes.object,
};

export default SentryProvider;

export const useSentry = () => useContext(SentryContext);
