import { isBrowser } from './is-browser';

const isStorageAvailable = (storageType) => {
  // try catch required to catch cases where the user has blocked sessionStorage/localStorage in their browser settings
  try {
    return window[`${storageType}Storage`].length >= 0;
  } catch (e) {
    return false;
  }
};

export const storageValueHasBeenSet = (id, storageType = 'session') => {
  if (isBrowser && isStorageAvailable(storageType)) {
    return window[`${storageType}Storage`].getItem(id) !== null;
  }
  return null;
};

// made dynamic to allow the use of 'local' storage when needed as per line 37 on src\components\alert-banner\index.js
export const getStorageValue = (item, id, storageType = 'session') => {
  if (isBrowser && isStorageAvailable(storageType)) {
    const storageObj = JSON.parse(window[`${storageType}Storage`].getItem(id));
    if (!storageObj) return false;
    return storageObj[`${item}`];
  }
  return false;
};

// get an array of storage obj instead of just single value by item
export const getAllStorageValues = (id, storageType = 'session') => {
  if (isBrowser && isStorageAvailable(storageType)) {
    const storageObj = JSON.parse(window[`${storageType}Storage`].getItem(id));
    if (!storageObj) return false;
    return storageObj;
  }
  return false;
};

export const setStorageValue = (item, value, id, storageType = 'session') => {
  if (isBrowser && isStorageAvailable(storageType)) {
    let storageObj = JSON.parse(window[`${storageType}Storage`].getItem(id));
    if (!storageObj) {
      storageObj = {
        [`${item}`]: value,
      };
    } else {
      storageObj[item] = value;
    }
    window[`${storageType}Storage`].setItem(id, JSON.stringify(storageObj));
  }
};

export const removeStorageValue = (item, id, storageType = 'session') => {
  if (isBrowser && isStorageAvailable(storageType)) {
    const storageObj = JSON.parse(window[`${storageType}Storage`].getItem(id));
    delete storageObj[`${item}`];
    window.sessionStorage.setItem(id, JSON.stringify(storageObj));
  }
};

export const getAnalyticsParams = () => {
  // Gets any params used for analytics from session storage and returns them
  const queryString = getStorageValue('query', 'storedEntry');

  // Make all utm paramater keys lowercase and convert to array
  const utmArray = Object.entries(queryString)
    .map(([value, key]) => [value.toLowerCase(), key])
    .filter(([key]) => key.toLowerCase().startsWith('utm_'));

  const utmParams = utmArray.length ? Object.fromEntries(utmArray) : null;

  const gclid = queryString?.gclid;
  const reservedAppealCode = queryString?.reserved_appeal_code;

  return {
    utmParams,
    gclid,
    reservedAppealCode,
  };
};
